import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { media } from '@caldera-digital/theme'

// eslint-disable-next-line import/named
import { Layout, SEO, Button } from '../components'

const PageContainer = styled.div`
  background-color: ${props => props.theme.black};
  flex: 1;
`

const PageWrapper = styled.div`
  margin: 0 auto;
  padding: 0 2rem;
  max-width: 1050px;

  h1 {
    margin: 100px 0 0 0;
    color: ${props => props.theme.white};
    font-size: 44px;
    font-weight: 200;
    line-height: 55px;
  }

  p {
    margin: 100px 0 0 0;
    color: #d8d8d8;
    font-size: 75px;
    font-weight: bold;
    letter-spacing: -5.7px;
    line-height: 1.5;
  }

  ${Button} {
    margin: 100px 0 0 0;
  }

  ${media.forSmallMediumOnly`
    padding: 0 4rem;

    h1 {
      margin: 60px 0 0 0;
      font-size: 38px;
      font-weight: 200;
      line-height: 1.4;
    }

    p {
      margin: 60px 0 0 0;
      font-size: 64px;
      line-height: 1.4;
    }

    ${Button} {
      margin: 60px 0 0 0;
    }
  `}

  ${media.forSmallOnly`
    padding: 0 2rem;

    h1 {
      margin: 40px 0 0 0;
      font-size: 24px;
      font-weight: 200;
      line-height: 1.4;
    }

    p {
      margin: 30px 0 0 0;
      font-size: 44px;
      line-height: 1.4;
      letter-spacing: -2px;
    }

    ${Button} {
      margin: 20px 0 0 0;
    }
  `}
`

const NotFoundPage = () => (
  <Layout fluid showFooter={false}>
    <SEO title="404: Not found" />
    <PageContainer>
      <PageWrapper>
        <h1>Error Code 404</h1>
        <p>We can&apos;t seem to find the page you&apos;re looking for.</p>
        <Link to="/">
          <Button>Go Home</Button>
        </Link>
      </PageWrapper>
    </PageContainer>
  </Layout>
)

export default NotFoundPage
